<template>
  <div style="text-align: center">
    <div v-if="loginIcon" class="loader"></div>
    <div v-if="noUser" style="margin-top: 140px">
      <el-image :src="require('/src/assets/image/malfunction.png')" style="width: 120px; height: 100px"/>
      <div style="font-size: 14px;color: #F56C6C;font-weight: bold;margin-top: 6px">程序出错啦 ~</div>
    </div>
  </div>
</template>

<script>
import { selectUser } from "@/api/org";
import {Notification} from "element-ui";
export default {
  name: "Index",
  data(){
    return{
      loginIcon:true,
      noUser:false,
    }
  },
  mounted(){
    this.login();
  },
  methods:{
    login(){
      let url = window.location.href;
      let type = url.match(/&type=(.*?)(&|$)/);
      if (type && type[1] && (type[1] == "mini")) {
        let miniToken = url.match(new RegExp("token=" + "(.*?)" + "&type"));
        sessionStorage.setItem("userType","mini");
        sessionStorage.setItem("token", miniToken && miniToken[1] ? miniToken[1]:'');
        this.selectUser("/workspaceMini")
      }else {
        sessionStorage.setItem("userType","pc");
        sessionStorage.setItem("token",document.cookie.substring(6));
        let user = {
          id : 1,
          name: "张腾辉"
        }
        sessionStorage.setItem("user", JSON.stringify(user))
        this.$router.replace('/formsPanel');
      }
    },
    selectUser(url){
      selectUser().then(val=>{
        this.loginIcon = false;
        if (val.status == 200 && val.data){
          sessionStorage.setItem("user", JSON.stringify(val.data))
          this.$router.replace(url);
        }else {
          this.noUser = true;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/* From Uiverse.io by SchawnnahJ */
.loader {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform: rotate(165deg);
}

.loader:before, .loader:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  transform: translate(-50%, -50%);
}

.loader:before {
  animation: before8 2s infinite;
}

.loader:after {
  animation: after6 2s infinite;
}

@keyframes before8 {
  0% {
    width: 0.5em;
    box-shadow: 1em -0.5em #F56C6C, -1em 0.5em #409EFF;
  }

  35% {
    width: 2.5em;
    box-shadow: 0 -0.5em #F56C6C, 0 0.5em #409EFF;
  }

  70% {
    width: 0.5em;
    box-shadow: -1em -0.5em #F56C6C, 1em 0.5em #409EFF;
  }

  100% {
    box-shadow: 1em -0.5em #F56C6C, -1em 0.5em #409EFF;
  }
}

@keyframes after6 {
  0% {
    height: 0.5em;
    box-shadow: 0.5em 1em #6cec2c, -0.5em -1em #ffd553;
  }

  35% {
    height: 2.5em;
    box-shadow: 0.5em 0 #6cec2c, -0.5em 0 #ffd553;
  }

  70% {
    height: 0.5em;
    box-shadow: 0.5em -1em #6cec2c, -0.5em 1em #ffd553;
  }

  100% {
    box-shadow: 0.5em 1em #6cec2c, -0.5em -1em #ffd553;
  }
}

.loader {
  position: absolute;
  top: calc(30% - 1.25em);
  left: calc(50% - 1.25em);
}
</style>
